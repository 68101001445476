module.exports = new Promise(function (resolve, reject) {
        var _a;
        var envGlobal = window === null || window === void 0 ? void 0 : window.__ENV__;
        var BASE_URL = (_a = envGlobal === null || envGlobal === void 0 ? void 0 : envGlobal.BASE_URL) !== null && _a !== void 0 ? _a : './';
        var appendScriptInHead = function (remote_url) {
            var script = document.createElement('script');
            script.src = remote_url;
            script.onload = function () {
                var proxy = {
                    get: function (request) { return window['react_delete_service'].get(request); },
                    init: function (arg) {
                        try {
                            return window['react_delete_service'].init(arg);
                        }
                        catch (e) {
                            console.log('remote container already initialized');
                        }
                    },
                };
                resolve(proxy);
            };
            script.onerror = function () {
                var message = "Unable to load ".concat("react-delete-service", " at ").concat(remote_url);
                reject(message);
            };
            document.head.appendChild(script);
        };
        if (!envGlobal && undefined) {
            var remote_url = undefined + '/remoteEntry.js';
            appendScriptInHead(remote_url);
        }
        else if (!envGlobal ||
            (envGlobal === null || envGlobal === void 0 ? void 0 : envGlobal.ENVIRONMENT) === 'development' ||
            (envGlobal === null || envGlobal === void 0 ? void 0 : envGlobal.ENVIRONMENT) === 'preview') {
            fetch(BASE_URL + 'react-preview-manager-service/v1/api/previews/' + "react-delete-service", {})
                .then(function (data) {
                if (data.status !== 200) {
                    console.error('remote UI', "react-delete-service", 'no access');
                    return '';
                }
                return data.text();
            })
                .then(function (previewCode) {
                var currentUrl = BASE_URL + 'app/';
                var remoteDefault = (envGlobal === null || envGlobal === void 0 ? void 0 : envGlobal.ENVIRONMENT) === 'preview'
                    ? currentUrl + "react-delete-service" + '/remoteEntry.js?v=' + Date.now()
                    : '/app/' + "react-delete-service" + '/remoteEntry.js?v=' + Date.now();
                var remoteUrlWithScript = remoteDefault;
                if (previewCode) {
                    remoteUrlWithScript = "".concat(currentUrl).concat("review-react-delete-service", "-").concat(previewCode, "/remoteEntry.js?v=").concat(Date.now());
                }
                fetch(remoteUrlWithScript)
                    .then(function (response) { return response.status === 200; })
                    .catch(function () { return false; })
                    .then(function (isSuccess) {
                    if (!isSuccess) {
                        console.log('remote UI isnt access, use UI by default');
                        remoteUrlWithScript = remoteDefault;
                    }
                    appendScriptInHead(remoteUrlWithScript);
                });
            });
        }
        else {
            var remoteUrlWithScript = '/app/' + "react-delete-service" + '/remoteEntry.js?v=' + Date.now();
            appendScriptInHead(remoteUrlWithScript);
        }
    });;